(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Phase
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('InvoiceRefLine', InvoiceRefLine);

  function InvoiceRefLine($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/invoicerefline/:lineId', {lineId: '@_lineId'}, {
      update: {
        method: 'PUT'
      },
      query: {
        method: 'GET',
        isArray: true
      },
       delete: {
        method: 'DELETE',
        isArray:true
       }
    });
  }
}());




